<template>
  <v-timeline-item color="undefined">
    <template v-slot:icon>
      <v-tooltip top :disabled="!hasDueDate">
        <template v-slot:activator="{ on }">
          <v-avatar v-on="on">
            <v-icon :color="dueDateColorClass(task)">{{ iconName }}</v-icon>
          </v-avatar>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </template>
    <v-row>
      <v-col cols="12" md="5">
        <div :class="columnHeaderClass">Due</div>
        <div :class="dueDateTextClass(task)">
          {{ getTaskDueDate(task) }}
        </div>
      </v-col>
    </v-row>
  </v-timeline-item>
</template>

<script>
import { iconExclamation } from "@/design/icon/iconConst";
import { timelineTaskMixin } from "@/mixins/shared/timeline/timelineTaskMixin";

export default {
  name: "TaskDueTimelineItem",
  mixins: [timelineTaskMixin],
  data() {
    return {
      iconName: iconExclamation
    };
  },
  props: {
    /**
     * @type {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee:string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole:string, canReassign:boolean, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone:boolean, isOverdue:boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled:boolean, workflowDefinitionId:number, notes: {id:number, taskId:number, text:string, userId:number, userName:string, created:string}[]}}
     */
    task: undefined
  },
  computed: {
    tooltip() {
      return `Due is on ${this.getTaskDueDate(this.task)}`;
    }
  }
};
</script>
